import React from 'react'
import {graphql, Link} from 'gatsby'
import Card from "../components/card/card";
import Menu from '../components/menu/menu';
import ContourHeader from "../components/contour-header/contour-header";
import Footer from "../components/footer/footer";

const IndexPage = ({data}) => (
    <>
        <div className="content">
            <Menu leftTitle="severin kilchhofer"/>
            <ContourHeader text="Ich bin Severin und arbeite als Frontend Entwickler und Interaction Designer in"
                           destination="Zürich"
                           ort="zurich"
                           arrowText="Projekte"
                           arrowColor="#0070BC"
                           arrowRef="#projekte"/>
            {data.allDatoCmsWork.edges.map(({node: work}) => (
                <div key={work.id}>
                    <div className="container">
                        <Card data={work}/>
                    </div>
                </div>
            ))}
        </div>
            <Footer/>
    </>
);

export default IndexPage

export const query = graphql`
    query IndexQuery {
        allDatoCmsWork(sort: { fields: [position], order: ASC }) {
            edges {
                node {
                    id
                    slug
                    backgroundColor {
                        hex
                    }
                    textColor {
                        hex
                    }
                    ...card
                }
            }
        }
    }
`
